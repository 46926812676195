import React from "react";
import "./Sass/Info.scss";


export default function Info() {

    return (
        <div className="Main" id="info">
            <div className="specifics">
                    <h1>Specifikācija:</h1>
                    <h2>Darbadienas - <strong>Pirmdiena - piektdiena</strong></h2>
                    <h2>Brīvdienas - <strong>Sestdiena un Svētdiena</strong></h2>
                    <h2>Svētki - <strong>Lieldienas, Jāņi/Līgo, Ziemassvētki un Vecgada vakars</strong></h2>
            </div>
            <div className="price">
                    <h1>Nomas cenas:</h1>
                    <h2>Darbadienas 24h - <strong>65 Eiro</strong></h2>                   
                    <h2>Brīvdienas 24h - <strong>85 Eiro</strong></h2>
                    <h2>Svētkos 48h - <strong>200 Eiro</strong></h2>
            </div>
            <div className="delivery">
                    <h1>Piegāde + opcijas:</h1>
                    <h2>Saņemšana nomas punktā (Der B kategorija) - <strong>Bezmaksas</strong></h2>
                    <h2>Piegāde uz Rīgu - <strong>10 Eiro</strong></h2>
                    <h2>Piegāde ārpus Rīgas - <strong>0,45/km</strong></h2>
                    -
                    <h2>Skaldīta bērza malka maisā 40l - <strong>8 Eiro</strong></h2>
            </div>
        </div>
    )
}