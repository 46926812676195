import React, { useState, useEffect} from 'react';
import {Link} from "react-scroll"
import "./Sass/NavBar.scss"



const StickyNavbar = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const stickyLimit = 10; 
    if (window.pageYOffset >= stickyLimit) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const [isShown, setIsShown] = useState(false);

  const [burger_class, setBurgerClass] = useState("burger-bar unclicked")

  const handleIsShown = () => {
    setIsShown(!isShown)
    if(isShown === true) {
      setBurgerClass("burger-bar unclicked")
    } else {
      setBurgerClass("burger-bar clicked")
    } 
  }; 


  useEffect(() => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    if (viewportWidth > 650) {
      setIsShown(true);
    } else {
      setIsShown(false);
    }

    const handleResize = () => {
      const updatedViewportWidth = window.innerWidth || document.documentElement.clientWidth;
      if (updatedViewportWidth > 650) {
        setIsShown(true);
      } else {
        setIsShown(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header>
        <nav className={isSticky ? 'navbar sticky' : 'navbar'}>
              <div className="burger-menu" onClick={handleIsShown}>
                  <div className={burger_class}></div>
                  <div className={burger_class}></div>
                  <div className={burger_class}></div>
              </div>
            <div className={isShown ? "nav--items" : "show"}>
              <Link to="main" spy={true} smooth={true} offset={0} duration={500}>Sākums</Link>
              <Link to="kubls" spy={true} smooth={true} offset={-250} duration={500}>Par Kublu</Link>
              <Link to="gallery" spy={true} smooth={true} offset={-150} duration={500}>Galerija</Link>
              <Link to="info" spy={true} smooth={true} offset={-400} duration={500}>Cenrādis</Link>
              <Link to="footer" spy={true} smooth={true} offset={0} duration={500}>Kontakti</Link>
            </div>
        </nav>
    </header>
  );
};

export default StickyNavbar;
