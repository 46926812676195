import React, { useRef, useEffect } from "react";
import "./Sass/ParKublu.scss";
import icon1 from "./Images/bubble.png";
import icon2 from "./Images/metallurgy.png";
import icon3 from "./Images/people.png";
import icon4 from "./Images/beer.png";
import icon5 from "./Images/led-light.png";



export default function Kubls() {

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
        // Set the default scroll position to the second item
        containerRef.current.scrollLeft = containerRef.current.children[1].offsetLeft;
        }
    }, []);

    return (
        <div className="main--area" id="kubls">
            <div className="line upper"></div>
            <div className="main--container" ref={containerRef}>
                <div className="main--text">
                    <h1>Steam&Dream <br></br>Kubla priekšrocības</h1>
                </div>
                <div className="container">   
                    <div className="Card">
                        <h1 className="dot">1</h1>
                        <h2>Hidromasāža</h2>
                        <img src={icon1} alt="bubbles" className="icon"/>
                    </div>
                    <div className="Card">
                        <h1 className="dot">2</h1>
                        <h2>Iebūvēta krāsns</h2>
                        <img src={icon2} alt="bubbles" className="icon"/>
                    </div>
                    <div className="Card first">
                        <h1 className="dot">3</h1>
                        <h2>Vieta 6 cilvēkiem</h2>
                        <img src={icon3} alt="bubbles" className="icon"/>
                    </div>
                    <div className="Card">
                        <h1 className="dot">4</h1>
                        <h2>Dzērienu turētāji</h2>
                        <img src={icon4} alt="bubbles" className="icon"/>
                    </div>
                    <div className="Card">
                        <h1 className="dot">5</h1>
                        <h2>LED apgaismojums</h2>
                        <img src={icon5} alt="bubbles" className="icon"/>
                    </div>
                </div>
                <div className="about">
                    <h2>PAR KUBLU</h2>
                    <h4>
                        Izbaudi siltu džakūzī vannu svaigā gaisā, draugu lokā, ar ģimeni vai viens pats.
                        Iebūvētā hidromasāža patīkami izmasēs Tavu muguru un kājas, kā arī radīs jauku burbuļvannas efektu.
                        Kubulā iebūvētais LED apgaismojums radīs neizmirstamu noskaņu,
                        un kopā ar iebūvētajiem dzērienu turētājiem varēsiet baudīt lielisku atpūtu neatkarīgi no gadalaika.
                    </h4>
                </div>
            </div>
        </div>
    );
}