import React from "react";
import "./Sass/Footer.scss";
import logo from "./Images/SteamDream.png";

import phone from "./Images/phone-call.png"
import email from "./Images/mail.png"
import location from "./Images/location.png"


export default function Footer() {

    return (
        <div className="footer" id="footer">
            <div className="contacts">
                <div className="logo">
                    <img src={logo} alt="logo"></img>
                </div>
                <div className="info">
                    <div className="contacts--div">
                        <h2>KONTAKTI</h2>
                        <div className="pair phone">
                            <img src={phone} alt="icon"></img>
                            <h4>+371 29758535</h4>
                        </div>
                        <div className="pair mail">
                            <img src={email} alt="icon"></img>
                            <h4>a.meksh@gmail.com</h4>
                        </div>
                        
                    </div>
                    <div className="location">
                        <h2>ATRODI MŪS</h2>
                        <div className="pair loc">
                            <img src={location} alt="icon"></img>
                            <h4>"Kalnbērzi", Plakanciems,<br></br> Ķekavas novads, LV-2113</h4>
                        </div>
                            <a target="blank" href="https://www.google.com/maps/place/Kalnb%C4%93rzi,+Plakanciems,+%C4%B6ekavas+pagasts,+LV-2113/@56.7666086,24.1074638,18.62z/data=!4m6!3m5!1s0x46eed59cee54d6d1:0x42cfe0ccd272479a!8m2!3d56.7667692!4d24.1064574!16s%2Fg%2F11mtmjyryg?entry=ttu">
                                Google Maps
                            </a>                    
                    </div>
                </div>
            </div>
        </div>
    )
}