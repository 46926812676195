import React, { useEffect } from 'react';
import "./Sass/form.scss"

const JotFormIframe = () => {
  useEffect(() => {
    const handleIFrameMessage = (e) => {
      if (typeof e.data === 'object') {
        return;
      }
      var args = e.data.split(':');
      var iframe;
      if (args.length > 2) {
        iframe = document.getElementById('JotFormIFrame-' + args[args.length - 1]);
      } else {
        iframe = document.getElementById('JotFormIFrame');
      }
      if (!iframe) {
        return;
      }
      switch (args[0]) {
        case 'scrollIntoView':
          iframe.scrollIntoView();
          break;
        case 'setHeight':
          iframe.style.height = args[1] + 'px';
          if (!isNaN(args[1]) && parseInt(iframe.style.minHeight) > parseInt(args[1])) {
            iframe.style.minHeight = args[1] + 'px';
          }
          break;
        case 'collapseErrorPage':
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + 'px';
          }
          break;
        case 'reloadPage':
          window.location.reload();
          break;
        case 'loadScript':
          if (!window.isPermitted(e.origin, ['jotform.com', 'jotform.pro'])) {
            break;
          }
          var src = args[1];
          if (args.length > 3) {
            src = args[1] + ':' + args[2];
          }
          var script = document.createElement('script');
          script.src = src;
          script.type = 'text/javascript';
          document.body.appendChild(script);
          break;
        case 'exitFullscreen':
          if (window.document.exitFullscreen) window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen) window.document.mozCancelFullscreen();
          else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen) window.document.msExitFullscreen();
          break;
      }
      var isJotForm = e.origin.indexOf('jotform') > -1 ? true : false;
      if (isJotForm && 'contentWindow' in iframe && 'postMessage' in iframe.contentWindow) {
        var urls = {
          docurl: encodeURIComponent(document.URL),
          referrer: encodeURIComponent(document.referrer),
        };
        iframe.contentWindow.postMessage(JSON.stringify({ type: 'urls', value: urls }), '*');
      }
    };

    const isPermitted = (originUrl, whitelisted_domains) => {
      var url = document.createElement('a');
      url.href = originUrl;
      var hostname = url.hostname;
      var result = false;
      if (typeof hostname !== 'undefined') {
        whitelisted_domains.forEach(function (element) {
          if (hostname.slice(-1 * element.length - 1) === '.'.concat(element) || hostname === element) {
            result = true;
          }
        });
        return result;
      }
    };

    if (window.addEventListener) {
      window.addEventListener('message', handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', handleIFrameMessage);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', handleIFrameMessage);
      } else if (window.detachEvent) {
        window.detachEvent('onmessage', handleIFrameMessage);
      }
    };
  }, []);

  const mediaQuery = window.matchMedia('(max-width: 650px)');

  const baseStyles = {
    minWidth: '60%',
    minHeight: '50vh',
    border: 'none',
    padding: '50px',
    borderRadius: '60px',
    boxShadow: 'none',
  };

  // Create a separate style object for the media query condition
  const mobileStyles = {
    minWidth: '95%', 
    minHeight: '70vh', 
    padding: '0px', 
    border: 'none',
    borderRadius: '40px',
    boxShadow: 'none',
  };

  // Apply styles based on the media query condition
  const styles = mediaQuery.matches ? mobileStyles : baseStyles;


  useEffect(() => {
    const delay = 1000; 
    const scrollToTarget = () => {
      const targetElement = document.getElementById('main');
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'auto' });
      }
    };

    const timeoutId = setTimeout(scrollToTarget, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className='form--div' id='forma'>
      <iframe
      id="JotFormIFrame-232134592135350"
      title="Clone of Steam & Dream card form"
      allowTransparency="true"
      allowFullScreen="false"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/232134592135350"
      frameBorder="0"
      style={styles}
      />
    </div>
  );
};

export default JotFormIframe;

 