import React from "react"
import logo from "./Images/SteamDream.png"
import {Link} from "react-scroll"
import "./Sass/Main.scss"


function Main() {

    return (
      <div className="main" id="main">
        <div className="cover">
        <div className="main--content">
          <img src={logo} className="logo"/>
          <div className="main--text">
            <h2>KUBLA NOMA</h2>
            <p>Veselīgai atpūtai un jautriem svētkiem<br></br> Tevis izvēlētā vietā un laikā!</p>
            <Link className="reserve--btn" to="forma" spy={true} smooth={true} offset={0} duration={500}>Rezervēt</Link>
          </div>
        </div>
        </div>
      </div>
    );
  }
  
  export default Main;