import NavBar from "./NavBar"
import Main from "./Main";
import Kubls from "./ParKublu"
import React, { useEffect } from "react"
import Gallery from "./Gallery";
import Footer from "./Footer";
import Info from "./Info";  
import JotFormIframe from "./Forma";

function App() {

  return (
    <div className="app" style={{backgroundColor: 'rgb(22, 22, 22)'}}>
      <NavBar />
      <div id="scroll-start">
        <Main />
      </div>
      <Kubls />
      <Gallery />
      <Info />
      <JotFormIframe />
      <Footer />
    </div>
  );
}

export default App;
