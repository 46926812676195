import React from "react";
import "./Sass/Gallery.scss";
import img1 from "./Images/galerija1.jpg";
import img2 from "./Images/galerija2.jpg";
import img3 from "./Images/galerija3.jpg";
import img4 from "./Images/galerija4.jpg";
import img5 from "./Images/galerija5.jpg";
import img6 from "./Images/galerija6.jpg";
import img7 from "./Images/galerija7.jpg";
import img8 from "./Images/galerija8.jpg";


import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const images = [img2, img5, img6, img7, img8, img3, img4, img1];

function Galerija() {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div>
      <div className="line"></div>
        <div className="slider" id="gallery">
        <Slider {...settings}>
          {images.map((img, idx) => (
            <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
              <img src={img} alt={img} />
            </div>
          ))}
        </Slider>
        </div>
      <div className="line lower"></div>
    </div>
  );
}

export default Galerija;